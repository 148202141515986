<template>
  <div class="d-flex align-center" :class="`background-auth${$vuetify.theme.dark ? '-dark' : ''}`" style="height: 100vh;">
    <v-card class="mx-auto text-left pa-8 bgPanel" :width="480" flat align="center" justify="center">
      <v-col class="text-center pa-0 mt-2 mb-6">
        <img :src="require(`@/assets/appBrand--ax-full${ $vuetify.theme.dark ? '-dark' : ''}.svg`)" width="211">
        <span class="mt-2 d-block subtitle-1 grey-500--text font-weight-bold">Aceptar invitación</span>
      </v-col>
      <v-form>
        <v-col class="pa-0 mt-5" v-if="!this.$route.query.invitation">
          <span class="body-1 grey-500--text">Email</span>
          <v-text-field v-model="user.email" @keyup.enter="signUp" :error="$v.user.email.$error" outlined hide-details required single-line dense maxlength="254" />
        </v-col>
        <v-col :class="`${$v.user.password1.minLength ? 'my-5' : 'mt-5 mb-3'} pa-0`">
          <span class="body-1 grey-500--text">Contraseña</span>
          <v-text-field class="icon-field" v-model="user.password1" @keyup.enter="signUp" :error="$v.user.password1.$error" @click:append="show1 = !show1" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" :type="show1 ? 'user.password1' : 'password'" outlined :hide-details="$v.user.password1.minLength" persistent-hint required single-line dense maxlength="20" autocomplete="new-password" hint="Escribe al menos 8 caracteres." />
        </v-col>
        <v-col class="pa-0 my-5">
          <span class="body-1 grey-500--text">Confirmar contraseña</span>
          <v-text-field class="icon-field" v-model="user.password2" @keyup.enter="signUp" :error="$v.user.password2.$error" @click:append="show = !show" :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'" :type="show ? 'user.password2' : 'password'" outlined :hide-details="$v.user.password2.sameAsPassword" persistent-hint required single-line dense maxlength="20" autocomplete="new-password" hint="La contraseña debe ser igual." />
        </v-col>
        <v-col cols="9" class="py-0 px-1 body-2 grey-500--text pr-6">
          Al {{ this.$route.query.invitation ? ' iniciar sesión' : 'crear la cuenta'}} aceptas nuestros términos de uso y política de privacidad.
        </v-col>
        <v-col v-if="error" class="py-0 px-2 text-center">
          <v-alert border="left" colored-border color="white" dense class="body-2 red--text pa-0">{{error}}</v-alert>
        </v-col>
        <v-col class="mb-6 mt-13 pa-0">
          <v-btn @click="signUp" :loading="signUpLoader" retain-focus-on-click color="blue-500" height="40" dark block :ripple="false">{{$route.query.invitation ? 'Unirse' : 'Comenzar'}}</v-btn>
        </v-col>
      </v-form>
      <v-divider />
      <v-col class="text-center pa-0 mt-6 mb-n2">
        <span class="d-block body-2 grey-500--text mt-5">¿Ya tienes una cuenta Axteroid? </span>
        <v-btn class="body-1 mt-1" :to="{name: 'AuthSignIn', params: $route.params}" text color="blue-500" small :ripple="false">Inicia sesión</v-btn>
      </v-col>
    </v-card>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import GenericViewMixin from '@/mixins/GenericViewMixin'
import { required, requiredIf, email, sameAs, minLength, maxLength } from 'vuelidate/lib/validators'
export default {
  mixins: [
    GenericViewMixin
  ],
  data: () => ({
    user: {
      email: '',
      password1: '',
      password2: ''
    },
    show: false,
    show1: false,
    error: '',
    signUpLoader: false,
    env: process.env?.VUE_APP_MODE ?? 'ax'
  }),
  computed: mapState({
    invitation: state => state.auth.invitation
  }),
  created () {
    if (this.$store.state.auth.user.authenticated) {
      this.$router.push({
        name: 'MetricsList'
      })
    }
  },
  methods: {
    signUp () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return false
      }
      this.signUpLoader = true

      return new Promise((resolve, reject) => {
        if (this.$route.query.invitation) {
          this.$store.dispatch('auth/ACCEPT_INVITATION', {
            key: this.$route.query.invitation,
            payload: this.user
          })
          // this.$store.dispatch('auth/ACCEPT_INVITATION', this.$route.query.invitation)
          .then((response) => {
            this.user.email = response.data.account_verified_email
            resolve()
          })
          .catch((error) => {
            this.$dialog.message.error(error.response.data.detail)
            reject(this.error)
          })
        } else {
          resolve()
        }
      })
      .then((response) => {
        this.$store.dispatch('auth/SIGN_UP', {
          user: this.user,
          invitation: this.$route.query.invitation
        })
        .then(() => {
          this.$store.dispatch('auth/GET_AUTH', {
            resource: '/token/',
            payload: {
              username: this.user.email,
              password: this.user.password1
            }
          })
        })
        .catch((error) => {
          const msg = this.parseErrors(error.response.data)
          this.$dialog.message.error(msg)
        })
      })
      .finally(() => {
        this.signUpLoader = false
      })
    }
  },
  validations: {
    user: {
      email: {
        required: requiredIf(function () {
          return !this.$route.query.invitation
        }),
        email,
        maxLength: maxLength(254)
      },
      password1: {
        required,
        minLength: minLength(8)
      },
      password2: {
        sameAsPassword: sameAs('password1')
      }
    }
  }
}
</script>